import PropTypes from 'prop-types'
// import { useTranslation } from '~/common/hooks/useTranslation'
import { Button, Col, Image, Modal, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import {
  converUserRankUser,
  getRankbyValue,
  StateSponsorTournament,
} from '~/common/constants'
import { useUser } from '~/views/app/redux/hooks/useUser'
import { useQueryState } from '~/common/hooks/useQueryState'

function SponsorTournamentDetail(props) {
  // const { t } = useTranslation()
  const { isShowModal, onClose, sponsor } = props

  const { page, pageSize } = useQueryState()
  const {
    actions,
    data: { listUserByWallet },
  } = useUser()
  const [userWallet, setUserWallet] = useState([])
  const [sortListWallet, setSortListWallet] = useState([])

  useEffect(() => {
    const userResult = sponsor?.final_standings
      ? sponsor.final_standings.map((item) => item.name)
      : []
    setUserWallet(userResult)
  }, [sponsor])

  useEffect(() => {
    if (sponsor && userWallet && userWallet.length > 0) {
      const params = {
        allWallets: userWallet,
        pageSize: pageSize,
        page,
      }
      actions.getUserByWallet(params)
    }
  }, [sponsor, userWallet, pageSize, page])

  useEffect(() => {
    // Sort listUserByWallet based on userWallet order
    const sortedList = [...listUserByWallet].sort((a, b) => {
      const indexA = userWallet.indexOf(a.address_wallet)
      const indexB = userWallet.indexOf(b.address_wallet)
      return indexA - indexB
    })

    const mergedList = sortedList.map((user) => {
      // Find the corresponding final standing by matching user_name or any other field
      const standing = sponsor?.final_standings?.find(
        (item) => item.name === user.address_wallet,
      )
      return standing
        ? {
            ...user,
            scoreTournament: standing.score || -1,
            rankTournament: standing.rank || 0,
          }
        : user // If no matching standing is found, return the user as is
    })
    if (userWallet.length > 0) {
      setSortListWallet(mergedList)
    } else {
      setSortListWallet([])
    }
  }, [listUserByWallet, userWallet, sponsor])

  const [listDetail, setListDetail] = useState('detail')
  const handleClose = () => {
    onClose()
  }

  return (
    <Modal
      title={'Sponsored Tournament Detail'}
      onCancel={handleClose}
      open={isShowModal}
      width={1000}
      centered
      destroyOnClose
      footer={null}
    >
      <div>
        <Row>
          <Col span={9}>
            <div>
              {sponsor?.banner !== '' && sponsor?.banner !== null ? (
                <div className="">
                  <Image
                    className="imageTournament"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                    src={sponsor?.banner}
                  />
                </div>
              ) : (
                <div></div>
              )}
            </div>
            <div className="text-[24px] mb-2 ml-3 ">
              {sponsor?.tournament_name}
            </div>
            <div
              style={{
                border: '1px solid #fff',
                padding: '8px',
                borderRadius: '8px',
              }}
            >
              <Row>
                <Col span={10} className="text-[12px]">
                  Date:
                </Col>
                <Col span={14} className="text-[12px]">
                  {new Date(sponsor?.date * 1000).toLocaleString('en-US', {
                    month: 'short', // 'Feb'
                    day: '2-digit', // '20'
                    year: 'numeric', // '2024'
                    hour: '2-digit', // '12'
                    minute: '2-digit', // '00'
                    hour12: false, // 24-hour format
                    timeZone: 'UTC', // Show UTC
                    timeZoneName: 'short', // 'UTC'
                  })}
                </Col>
              </Row>
              <Row>
                <Col span={10} className="text-[12px]">
                  Registration:
                </Col>
                <Col span={14} className="text-[12px]">
                  {new Date((sponsor?.date - 86400) * 1000).toLocaleString(
                    'en-US',
                    {
                      month: 'short', // 'Feb'
                      day: '2-digit', // '19' after subtracting 1 day
                      year: 'numeric', // '2024'
                      hour: '2-digit', // '12'
                      minute: '2-digit', // '00'
                      hour12: false, // 24-hour format
                      timeZone: 'UTC', // Show UTC
                      timeZoneName: 'short', // 'UTC'
                    },
                  )}
                </Col>
              </Row>
              <Row>
                <Col span={10} className="text-[12px]">
                  Entry:
                </Col>
                <Col span={14} className="text-[12px]">
                  {new Date((sponsor?.date - 1800) * 1000).toLocaleString(
                    'en-US',
                    {
                      month: 'short', // 'Feb'
                      day: '2-digit', // '20'
                      year: 'numeric', // '2024'
                      hour: '2-digit', // '11' if 30 minutes is subtracted from 12:00
                      minute: '2-digit', // '30'
                      hour12: false, // 24-hour format
                      timeZone: 'UTC', // Show UTC
                      timeZoneName: 'short', // 'UTC'
                    },
                  )}
                </Col>
              </Row>
              <Row>
                <Col span={10} className="text-[12px]">
                  Jump-in:
                </Col>
                <Col span={14} className="text-[12px]">
                  {new Date((sponsor?.date - 300) * 1000).toLocaleString(
                    'en-US',
                    {
                      month: 'short', // 'Feb'
                      day: '2-digit', // '19' after subtracting 5 minutes
                      year: 'numeric', // '2024'
                      hour: '2-digit', // '12'
                      minute: '2-digit', // '00'
                      hour12: false, // 24-hour format
                      timeZone: 'UTC', // Show UTC
                      timeZoneName: 'short', // 'UTC'
                    },
                  )}
                </Col>
              </Row>
            </div>
            <div className="mt-2" style={{ fontFamily: 'Rexliafree' }}>
              <div className="flex items-center">
                <div>Sponsor: {sponsor?.tournament_name}</div>
                <div className="ml-[10%]">
                  ID: {sponsor?._id ? `${sponsor._id.slice(0, 5)}...` : ''}
                </div>
              </div>
              <div>Type: {sponsor?.type}</div>
              <div># of participants: {sponsor?.participants}</div>
              <div># of registrations: {sponsor?.register_list?.length}</div>
              <div>AL: {sponsor?.use_allow_list ? 'OK' : 'No'}</div>
              <div>Jump-in: {sponsor?.jump_in ? 'OK' : 'No'}</div>
              <div className="flex items-center">
                Prize:
                {sponsor?.prize?.map((item, index) => (
                  <div className="flex items-center ml-1">
                    #{index + 1}
                    <img
                      className="w-[25px] h-[25px]"
                      src={`${process.env.PUBLIC_URL}/svgs/guild/oas.svg`}
                      alt=""
                    />
                    {item}
                    {sponsor.prize.length > 2 &&
                      index < sponsor.prize.length - 1 &&
                      ','}
                  </div>
                ))}
              </div>
            </div>
          </Col>
          <Col span={1}>
            <div
              style={{
                borderLeft: '1px solid #fff',
                height: '100%',
                marginLeft: '50%',
              }}
            ></div>
          </Col>
          {listDetail === 'detail' && (
            <Col span={14}>
              <div>
                <Row gutter={18}>
                  <Col span={12}>
                    <div
                      style={{
                        border: '1px solid rgba(0, 0, 0, 0.5)',
                        padding: '8px',
                        borderRadius: '8px',
                        fontFamily: 'Rexliafree',
                        background: 'rgba(0, 0, 0, 0.5)',
                      }}
                    >
                      <Row>
                        <Col span={24} className="text-[16px]">
                          Rules
                        </Col>
                      </Row>
                      <Row>
                        <Col span={14}>Rank</Col>
                        <Col span={10}>
                          {getRankbyValue(sponsor?.monster_rank)}
                        </Col>
                      </Row>
                      <Row>
                        <Col span={14}>Monster</Col>
                        <Col span={10}>{sponsor?.monsters}</Col>
                      </Row>
                      <Row>
                        <Col span={14}>Turns</Col>
                        <Col span={10}>{sponsor?.turns}</Col>
                      </Row>
                      <Row>
                        <Col span={14}>Special Hex</Col>
                        <Col span={10}>{sponsor?.special_hex}</Col>
                      </Row>
                      <Row>
                        <Col span={14}>Memory</Col>
                        <Col span={10}>{sponsor?.memory ? 'Yes' : 'No'}</Col>
                      </Row>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div
                      style={{
                        border: '1px solid rgba(0, 0, 0, 0.5)',
                        padding: '8px',
                        borderRadius: '8px',
                        fontFamily: 'Rexliafree',
                        background: 'rgba(0, 0, 0, 0.5)',
                      }}
                    >
                      <Row>
                        <Col span={24} className="text-[16px]">
                          Player Requirements
                        </Col>
                      </Row>
                      <Row>
                        <Col span={14}>SMS Verification</Col>
                        <Col span={10}>{sponsor?.sms ? 'Yes' : 'No'}</Col>
                      </Row>
                      <Row>
                        <Col span={14}>Player Rank</Col>
                        <Col span={10}>
                          {converUserRankUser(sponsor?.player_rank)}
                        </Col>
                      </Row>
                      <Row>
                        <Col span={14}>Cycle Score</Col>
                        <Col span={10}>{sponsor?.cycle_score_min}</Col>
                      </Row>
                      <Row>
                        <Col span={14}>Battles</Col>
                        <Col span={10}>{sponsor?.battles_min}</Col>
                      </Row>
                      <Row>
                        <Col span={14}>Playing Days</Col>
                        <Col span={10}>{sponsor?.playing_days_min}</Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
                <Row gutter={18} className="mt-4">
                  <Col span={24}>
                    <div
                      style={{
                        border: '1px solid rgba(0, 0, 0, 0.5)',
                        padding: '8px',
                        borderRadius: '8px',
                        fontFamily: 'Rexliafree',
                        background: 'rgba(0, 0, 0, 0.5)',
                      }}
                    >
                      <div className="text-[16px]">Monster Requirements</div>
                      <Row>
                        <Col span={6}>Cycle</Col>
                        <Col span={18}>{sponsor?.cycle?.length}</Col>
                      </Row>
                      <Row>
                        <Col span={6}>Type</Col>
                        <Col span={18}>{sponsor?.monster_type}</Col>
                      </Row>
                      <Row>
                        <Col span={6}>Age</Col>
                        <Col span={18}>
                          {sponsor?.age_min}~{sponsor?.age_max}
                        </Col>
                      </Row>
                      <Row>
                        <Col span={6}>Terrain</Col>
                        <Col span={18} className="flex items-center">
                          {sponsor?.terrain_ban?.map((item, index) => (
                            <img
                              key={index}
                              className="w-[20px] h-[20px] mr-1"
                              src={`${process.env.PUBLIC_URL}/svgs/tournament/terrain/${item}.svg`}
                              alt=""
                            />
                          ))}
                          {sponsor?.terrain_ban?.length === 0 && <>None</>}
                        </Col>
                      </Row>
                      {/* main_seed_ban */}
                      {sponsor?.main_seed_ban?.length === 0 && (
                        <Row>
                          <Col span={6}>Main Seed</Col>
                          <Col span={18} className="flex items-center">
                            {sponsor?.terrain_ban?.length === 0 && <>None</>}
                          </Col>
                        </Row>
                      )}
                      {sponsor?.main_seed_ban?.length !== 0 && (
                        <>
                          <div>Main Seed</div>
                          <div className="flex flex-wrap items-center">
                            {sponsor?.main_seed_ban?.map((item, index) => (
                              <img
                                key={index}
                                className="w-[40px] h-[40px] mt-1"
                                src={`${process.env.PUBLIC_URL}/svgs/tournament/mainseed/${item}.svg`}
                                alt=""
                              />
                            ))}
                          </div>
                        </>
                      )}
                      {/* sub_seed_ban */}
                      {sponsor?.sub_seed_ban?.length === 0 && (
                        <Row>
                          <Col span={6}>Sub Seed</Col>
                          <Col span={18} className="flex items-center">
                            {sponsor?.terrain_ban?.length === 0 && <>None</>}
                          </Col>
                        </Row>
                      )}
                      {sponsor?.sub_seed_ban?.length !== 0 && (
                        <>
                          <div>Sub Seed</div>
                          <div className="flex flex-wrap items-center">
                            {sponsor?.sub_seed_ban?.map((item, index) => (
                              <img
                                key={index}
                                className="w-[40px] h-[40px] mt-1"
                                src={`${process.env.PUBLIC_URL}/svgs/tournament/mainseed/${item}.svg`}
                                alt=""
                              />
                            ))}
                          </div>
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          )}
          {listDetail === 'listResult' && (
            <Col span={14} style={{ fontFamily: 'Rexliafree' }}>
              <div className="flex items-center justify-center text-[24px]">
                {sponsor.state === 2 || sponsor.state === 3
                  ? `Waiting for Entry (${sponsor.register_list?.length}/${sponsor.participants})`
                  : 'Congratulations!!!'}
              </div>
              {sponsor.state === 5 &&
                sortListWallet?.map((result, index) => (
                  <div key={index} className={index === 0 ? 'mt-4' : ''}>
                    <div className="flex justify-center text-[20px] h-[50px]">
                      <div
                        className="flex items-center w-[350px] h-full justify-start p-4"
                        style={
                          index !== 0
                            ? {
                                background: 'rgba(24, 45, 86, 1)',
                                color: 'rgba(255, 255, 255, 1)',
                                borderRight: '2px solid rgba(136, 136, 136, 1)',
                                borderLeft: '2px solid rgba(136, 136, 136, 1)',
                                borderBottom:
                                  '2px solid rgba(136, 136, 136, 1)',
                                position: 'relative',
                              }
                            : {
                                background: 'rgba(24, 45, 86, 1)',
                                color: 'rgba(255, 255, 255, 1)',
                                border: '2px solid rgba(136, 136, 136, 1)',
                                position: 'relative',
                              }
                        }
                      >
                        <img
                          className="absolute left-[-35px] top-[3px]"
                          src={`${process.env.PUBLIC_URL}/svgs/guild/level/${result.rankTournament}.svg`}
                          alt=""
                          style={{
                            zIndex: 1,
                            width:
                              result.rankTournament === 1 ? '45px' : '40px',
                            height:
                              result.rankTournament === 1 ? '45px' : '40px',
                          }}
                        />
                        <img
                          className="w-[35px] h-[35px] mr-4 rounded-full"
                          // src={`${process.env.PUBLIC_URL}/svgs/guild/testGuildIcon.svg`}
                          src={`${result.avatar}`}
                          alt=""
                        />
                        <div
                          className="mr-4"
                          style={{
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                          }}
                        >
                          {result.user_name}
                        </div>
                      </div>

                      <div
                        className="flex items-center justify-center"
                        style={
                          index !== 0
                            ? {
                                borderRight: '2px solid rgba(136, 136, 136, 1)',
                                borderBottom:
                                  '2px solid rgba(136, 136, 136, 1)',
                                background: 'rgba(255, 255, 255, 1)',
                                color: 'rgba(0, 0, 0, 1)',
                                width: '50px',
                                height: '50px',
                              }
                            : {
                                borderRight: '2px solid rgba(136, 136, 136, 1)',
                                borderTop: '2px solid rgba(136, 136, 136, 1)',
                                borderBottom:
                                  '2px solid rgba(136, 136, 136, 1)',
                                background: 'rgba(255, 255, 255, 1)',
                                color: 'rgba(0, 0, 0, 1)',
                                width: '50px',
                                height: '50px',
                              }
                        }
                      >
                        {result.scoreTournament}
                      </div>
                    </div>
                  </div>
                ))}

              {sponsor.state === 4 &&
                sortListWallet?.map((result, index) => (
                  <div
                    key={index}
                    className={index === 0 ? 'mt-4' : ''}
                    style={{
                      position: 'relative',
                    }}
                  >
                    <div className="flex justify-center text-[20px] h-[50px]">
                      <div
                        className="flex items-center w-[350px] h-full justify-start p-4"
                        style={
                          index !== 0
                            ? {
                                background: 'rgba(24, 45, 86, 1)',
                                color: 'rgba(255, 255, 255, 1)',
                                borderRight: '2px solid rgba(136, 136, 136, 1)',
                                borderLeft: '2px solid rgba(136, 136, 136, 1)',
                                borderBottom:
                                  '2px solid rgba(136, 136, 136, 1)',
                              }
                            : {
                                background: 'rgba(24, 45, 86, 1)',
                                color: 'rgba(255, 255, 255, 1)',
                                border: '2px solid rgba(136, 136, 136, 1)',
                              }
                        }
                      >
                        <img
                          className="w-[35px] h-[35px] mr-4 rounded-full"
                          // src={`${process.env.PUBLIC_URL}/svgs/guild/testGuildIcon.svg`}
                          src={`${result.avatar}`}
                          alt=""
                        />
                        <div
                          className="mr-4"
                          style={{
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                          }}
                        >
                          {result.user_name}
                        </div>
                      </div>

                      <div
                        className="flex items-center justify-center"
                        style={
                          index !== 0
                            ? {
                                borderRight: '2px solid rgba(136, 136, 136, 1)',
                                borderBottom:
                                  '2px solid rgba(136, 136, 136, 1)',
                                background: 'rgba(255, 255, 255, 1)',
                                color: 'rgba(0, 0, 0, 1)',
                                width: '50px',
                                height: '50px',
                              }
                            : {
                                borderRight: '2px solid rgba(136, 136, 136, 1)',
                                borderTop: '2px solid rgba(136, 136, 136, 1)',
                                borderBottom:
                                  '2px solid rgba(136, 136, 136, 1)',
                                background: 'rgba(255, 255, 255, 1)',
                                color: 'rgba(0, 0, 0, 1)',
                                width: '50px',
                                height: '50px',
                              }
                        }
                      >
                        {result.scoreTournament}
                      </div>
                    </div>
                  </div>
                ))}
              {(sponsor.state === 2 || sponsor.state === 3) &&
                sortListWallet?.map((result, index) => (
                  <div
                    key={index}
                    className={index === 0 ? 'mt-4' : ''}
                    style={{
                      position: 'relative',
                    }}
                  >
                    <div className="flex justify-center text-[20px] h-[50px]">
                      <div
                        className="flex items-center w-[350px] h-full justify-start p-4"
                        style={
                          index !== 0
                            ? {
                                background: 'rgba(24, 45, 86, 1)',
                                color: 'rgba(255, 255, 255, 1)',
                                borderRight: '2px solid rgba(136, 136, 136, 1)',
                                borderLeft: '2px solid rgba(136, 136, 136, 1)',
                                borderBottom:
                                  '2px solid rgba(136, 136, 136, 1)',
                              }
                            : {
                                background: 'rgba(24, 45, 86, 1)',
                                color: 'rgba(255, 255, 255, 1)',
                                border: '2px solid rgba(136, 136, 136, 1)',
                              }
                        }
                      >
                        <img
                          className="w-[35px] h-[35px] mr-4 rounded-full"
                          // src={`${process.env.PUBLIC_URL}/svgs/guild/testGuildIcon.svg`}
                          src={`${result.avatar}`}
                          alt=""
                        />
                        <div
                          className="mr-4"
                          style={{
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                          }}
                        >
                          {result.user_name}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </Col>
          )}
        </Row>
        <div>
          {listDetail === 'detail' &&
            (sponsor?.state === 1 || sponsor?.state === 0 ? (
              <Button
                style={{
                  width: '20%',
                  marginTop: '20px',
                  marginLeft: '61%',
                  background: 'rgba(74, 175, 79, 1)',
                  border: '2px solid rgba(74, 175, 79, 1)',
                  pointerEvents: 'none',
                }}
              >
                {StateSponsorTournament(sponsor?.state)}
              </Button>
            ) : (
              <Button
                style={{
                  width: '20%',
                  marginTop: '20px',
                  marginLeft: '61%',
                  background: 'rgba(74, 175, 79, 1)',
                  border: '2px solid rgba(74, 175, 79, 1)',
                }}
                onClick={() => setListDetail('listResult')}
              >
                Watch
              </Button>
            ))}
          {listDetail === 'listResult' && (
            <Button
              style={{
                width: '20%',
                marginTop: '20px',
                marginLeft: '61%',
                background: 'rgba(255, 0, 0, 1)',
                border: '2px solid rgba(255, 0, 0, 1)',
              }}
              onClick={() => setListDetail('detail')}
            >
              Exit
            </Button>
          )}
        </div>
      </div>
    </Modal>
  )
}

SponsorTournamentDetail.propTypes = {
  isShowModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  id: PropTypes.string,
}

export default SponsorTournamentDetail
