export const GET_LIST_USER_START = 'GET_LIST_USER_START'
export const GET_LIST_USER_SUCCESS = 'GET_LIST_USER_SUCCESS'
export const GET_LIST_USER_FAILED = 'GET_LIST_USER_FAILED'

export const GET_DETAIL_USER_START = 'GET_DETAIL_USER_START'
export const GET_DETAIL_USER_SUCCESS = 'GET_DETAIL_USER_SUCCESS'
export const GET_DETAIL_USER_FAILED = 'GET_DETAIL_USER_FAILED'

export const GET_USER_BY_WALLET_START = 'GET_USER_BY_WALLET_START'
export const GET_USER_BY_WALLET_SUCCESS = 'GET_USER_BY_WALLET_SUCCESS'
export const GET_USER_BY_WALLET_FAILED = 'GET_USER_BY_WALLET_FAILED'

//-------------------------user------------------------//
/**
 * [1]
 * get list user start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getListUser(payload, onSuccess, onError) {
  return {
    type: GET_LIST_USER_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get list user success action
 * @param {*} payload
 * @returns {object}
 */
export function getListUserSuccess(payload) {
  return {
    type: GET_LIST_USER_SUCCESS,
    payload: payload,
  }
}

/**
 * get list user failed action
 * @returns {object}
 */
export function getListUserFailed() {
  return {
    type: GET_LIST_USER_FAILED,
  }
}

/**
 * [2]
 * get detail user start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getDetailUser(payload, onSuccess, onError) {
  return {
    type: GET_DETAIL_USER_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get detail user success action
 * @param {*} payload
 * @returns {object}
 */
export function getDetailUserSuccess(payload) {
  return {
    type: GET_DETAIL_USER_SUCCESS,
    payload: payload,
  }
}

/**
 * get detail user failed action
 * @returns {object}
 */
export function getDetailUserFailed() {
  return {
    type: GET_DETAIL_USER_FAILED,
  }
}

/**
 * [3]
 * get user by wallet start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getUserByWallet(payload, onSuccess, onError) {
  return {
    type: GET_USER_BY_WALLET_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get user by wallet success action
 * @param {*} payload
 * @returns {object}
 */
export function getUserByWalletSuccess(payload) {
  return {
    type: GET_USER_BY_WALLET_SUCCESS,
    payload: payload,
  }
}

/**
 *  get user by wallet failed action
 * @returns {object}
 */
export function getUserByWalletFailed() {
  return {
    type: GET_USER_BY_WALLET_FAILED,
  }
}

export default {
  getListUser,
  getListUserSuccess,
  getListUserFailed,

  getDetailUser,
  getDetailUserSuccess,
  getDetailUserFailed,

  getUserByWallet,
  getUserByWalletSuccess,
  getUserByWalletFailed,
}
