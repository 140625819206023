export const GET_LIST_SECTORS_START = 'GET_LIST_SECTORS_START'
export const GET_LIST_SECTORS_SUCCESS = 'GET_LIST_SECTORS_SUCCESS'
export const GET_LIST_SECTORS_FAILED = 'GET_LIST_SECTORS_FAILED'

//------------------------- Shop ------------------------//
/**
 * [1]
 * get list sector start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getListSectors(payload, onSuccess, onError) {
  return {
    type: GET_LIST_SECTORS_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get list sector success action
 * @param {*} payload
 * @returns {object}
 */
export function getListSectorsSuccess(payload) {
  return {
    type: GET_LIST_SECTORS_SUCCESS,
    payload: payload,
  }
}

/**
 * get list sector failed action
 * @returns {object}
 */
export function getListSectorsFailed() {
  return {
    type: GET_LIST_SECTORS_FAILED,
  }
}

export default {
  getListSectors,
  getListSectorsSuccess,
  getListSectorsFailed,
}
