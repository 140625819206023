import { useTranslation } from '~/common/hooks/useTranslation'
import { Button, Col, Form, Input, Row, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import Datatable from '~/components/shared-components/Datatable'
import { useQueryState } from '~/common/hooks/useQueryState'
import UserGuild from './UserDetail'
import GuildDetail from './GuildDetail'
import { formatNumber } from '~/helpers/common'
import { DeleteFilled } from '@ant-design/icons'
import { useGuild } from '../../redux/hooks/useGuild'
import ConfirmDeleteGuild from './confirmDeleteGuild'
import { dataGuildLevel } from '~/common/constants'

const ManagementGuilds = () => {
  const { t } = useTranslation()
  const [formSearch] = Form.useForm()

  const { page, pageSize, keyword, setPage, setPageSize, setKeyword } =
    useQueryState()

  const [modalForm, setModalForm] = useState({
    data: null,
    isOpenModal: false,
  })
  const [modalFormProfileGuild, setModalFormProfileGuild] = useState({
    data: null,
    isOpenModal: false,
  })
  const [isModalOpenDelete, setIsModalOpenDelete] = useState({
    isOpenModal: false,
  })
  const showModalDelete = (content, data) => {
    setIsModalOpenDelete({
      content: content,
      data: data,
      isOpenModal: true,
    })
  }
  const {
    actions,
    data: { listGuild, isLoading },
  } = useGuild()

  const refreshData = () => {
    const params = {
      guild_name: keyword,
      // pageSize: pageSize,
      // page,
    }

    actions.getListGuilds(params)
  }

  useEffect(() => {
    refreshData()
  }, [page, pageSize, keyword])
  const onRemove = (data) => {
    showModalDelete(t('cancel_modal.question', { question: '' }), data)
  }
  const columns = [
    // {
    //   title: t('common.no'),
    //   dataIndex: 'key',
    //   key: 'key',
    //   render: (data) => {
    //     return data
    //   },
    // },
    {
      title: (
        <div className="flex items-center">
          <img
            className="w-[25px] h-[25px] mr-2 mt-[2px]"
            src={`${process.env.PUBLIC_URL}/svgs/guild/guild.svg`}
            alt=""
          />
          {t('managementGuilds.form.nameGuild')}
        </div>
      ),
      dataIndex: 'guild_name',
      key: 'guild_name',
      render: (guild_name) => {
        return (
          <div className="" style={{ fontFamily: 'Rexliafree' }}>
            {guild_name}
          </div>
        )
      },
    },
    {
      title: t('managementGuilds.form.level'),
      dataIndex: 'guild_level',
      key: 'guild_level',
      render: (guild_level) => {
        return <div style={{ fontFamily: 'Rexliafree' }}>{guild_level}</div>
      },
    },

    {
      title: t('managementGuilds.form.rank'),
      dataIndex: 'rank',
      key: 'rank',
      render: (_record, rank) => {
        return (
          <div className="flex" style={{ fontFamily: 'Rexliafree' }}>
            {rank.rank || rank.rankMax ? (
              <>
                <img
                  className="w-[20px] h-[20px] mr-2 mt-[2px]"
                  src={`${process.env.PUBLIC_URL}/svgs/guild/rank.svg`}
                  alt=""
                />
                {rank.rank}/{rank.rankMax}
              </>
            ) : (
              ''
            )}
          </div>
        )
      },
    },

    {
      title: (
        <div className="flex ml-[3rem]">{t('managementGuilds.form.score')}</div>
      ),
      dataIndex: 'score',
      width: 300,
      key: 'score',
      render: (_, data) => {
        return (
          <div className="flex-center" style={{ fontFamily: 'Rexliafree' }}>
            {data?.score?.length > 0 ? (
              <Row gutter={24}>
                <Col span={8} className="flex">
                  <div className="flex items-center justify-center">
                    <img
                      className="w-[20px] h-[20px] mr-3 "
                      src={`${process.env.PUBLIC_URL}/svgs/guild/score.svg`}
                      alt=""
                    />
                  </div>
                  <div>
                    <div>Total</div>
                    <div>Month</div>
                    <div>Cycle</div>
                  </div>
                </Col>
                <Col span={16}>
                  {data?.score?.map((parData, index) => (
                    <React.Fragment key={index}>
                      <div className="flex flex-col mb-1 items-start">
                        <div className="flex items-center h-[25px] flex-col  ">
                          <div>{formatNumber(parData.total)}</div>
                          <div>{formatNumber(parData.Month)}</div>
                          <div>{formatNumber(parData.Cycle)}</div>
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                </Col>
              </Row>
            ) : (
              ''
            )}
          </div>
        )
      },
    },
    {
      title: (
        <div className="flex items-center">
          <img
            className="w-[15px] h-[15px] mr-2 mt-[2px]"
            src={`${process.env.PUBLIC_URL}/svgs/guild/master.svg`}
            alt=""
          />
          {t('managementGuilds.form.master')}
        </div>
      ),
      dataIndex: 'master',
      key: 'master',
      render: (master) => {
        return (
          <div className="" style={{ fontFamily: 'Rexliafree' }}>
            {master ? master : ''}
          </div>
        )
      },
    },
    {
      title: t('managementGuilds.form.member'),
      dataIndex: 'guild_member',
      key: 'guild_member',
      render: (_record, member) => {
        const maxMembers = dataGuildLevel(member?.guild_level)?.maxMembers
        return (
          <div style={{ fontFamily: 'Rexliafree' }}>
            {_record?.length}/{maxMembers}
          </div>
        )
      },
    },
    {
      title: t('common.action'),
      render: (_, data) => {
        return (
          <Space>
            <Button
              type="ghost"
              loading={false}
              icon={
                <img
                  className="w-[20px] h-[20px]"
                  src={`${process.env.PUBLIC_URL}/svgs/guild/infoGuild.svg`}
                  alt=""
                />
              }
              onClick={() => {
                onShowModalFormGuild(data, data)
              }}
            />
            <Button
              type="ghost"
              loading={false}
              onClick={() => onShowModalForm(data, data)}
              icon={
                <img
                  className="w-[20px] h-[20px]"
                  src={`${process.env.PUBLIC_URL}/svgs/guild/userGuild.svg`}
                  alt=""
                />
              }
            />
            {/* <Button
              type="ghost"
              loading={false}
              // onClick={() => onShowModalForm(data?._id, data)}
              // icon={<DeleteOutlined style={{ fontSize: '20px', color: 'red' }} />}
              icon={<DeleteFilled style={{ color: '#FF4D4F' }} />}
            /> */}
            <Button
              type="ghost"
              loading={false}
              icon={<DeleteFilled style={{ color: '#FF4D4F' }} />}
              // onClick={() => onShowModalForm(data?._id, data)}
              onClick={() => {
                onRemove(data)
              }}
            />
          </Space>
        )
      },
    },
  ]

  //Refresh data
  //   const refreshData = () => {
  //     const params = {
  //       search: keyword,
  //       pageSize: pageSize,
  //       page,
  //     }
  //     actions.getListUser(params)
  //   }
  // Handle open modal (create-edit-delete)
  const onShowModalForm = (data) => {
    setModalForm({
      data,
      isOpenModal: true,
    })
  }

  const onShowModalFormGuild = (data) => {
    setModalFormProfileGuild({
      data,
      isOpenModal: true,
    })
  }

  //   // Handle close modal (create-edit-delete)
  const onCloseModalForm = () => {
    setModalForm({
      data: null,
      isOpenModal: false,
    })
  }
  const onCloseModalFormGuild = () => {
    setModalFormProfileGuild({
      data: null,
      isOpenModal: false,
    })
  }

  const handleSearch = (values) => {
    setKeyword(values?.keyword)
  }
  const handleCancelDelete = () => {
    setIsModalOpenDelete({
      content: null,
      data: null,
      isOpenModal: false,
    })
  }
  //   useEffect(() => {
  //     refreshData()
  //   }, [page, pageSize, keyword])
  return (
    <div>
      <Row style={{ marginBottom: 16 }} gutter={[16, 16]}>
        <Col xs={24} sm={12}></Col>
        <Col xs={24} sm={12}>
          <div className="flex sm:justify-end w-full">
            <div className="flex gap-0 sm:gap-3 flex-wrap mb-3 sm:mb-0 w-full sm:justify-end">
              <Form
                onFinish={handleSearch}
                className="w-full sm:w-auto"
                form={formSearch}
                translate="yes"
                autoComplete="off"
                size="middle"
                validateTrigger={['onBlur', 'onChange']}
                requiredMark={false}
              >
                <Form.Item
                  className="w-full"
                  label={
                    <span className="font__M__plus font-bold">
                      {t('common.search')}
                    </span>
                  }
                  name="keyword"
                  labelAlign="left"
                >
                  <Input className="w-full" placeholder={t('common.search')} />
                </Form.Item>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
      <Datatable
        scroll={{ x: 1300 }}
        columns={columns}
        dataSource={listGuild}
        loading={isLoading}
        showPagination
        showHeader
        metadata={{
          perPage: pageSize,
          setPerPage: setPageSize,
          page,
          setPage,
          // total: fakeData?.total,
          total: listGuild?.length,
          keyword,
          setKeyword,
        }}
      />

      {modalForm.isOpenModal && (
        <UserGuild
          isShowModal={modalForm.isOpenModal}
          onClose={onCloseModalForm}
          data={modalForm.data}
        />
      )}
      {modalFormProfileGuild.isOpenModal && (
        <GuildDetail
          isShowModal={modalFormProfileGuild.isOpenModal}
          onClose={onCloseModalFormGuild}
          data={modalFormProfileGuild.data}
        />
      )}

      <ConfirmDeleteGuild
        action={{
          isModalOpen: isModalOpenDelete.isOpenModal,
          handleCancel: handleCancelDelete,
        }}
        refreshData={refreshData}
        dataGuild={isModalOpenDelete.data}
        t={t}
      />
    </div>
  )
}

export default ManagementGuilds
